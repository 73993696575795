import styled from 'styled-components';

const ButtonGhostInverse = styled.button`
  border: 0;
  padding: 1rem 1.25rem;
  background: transparent;
  color: black;
  box-shadow: inset 0px 0px 0px 2px black;
  border-radius: 2px;
  &:hover {
    color: white;
    background: black;
  }
`;

export default ButtonGhostInverse;
