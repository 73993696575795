import React from "react"

import { Row, Col } from "react-styled-flexboxgrid"
import { navigate } from "gatsby"
import { Parallax, Primary, Alternate } from "../Section"
import Button from "../Button"
import AboutUsImg from "../../images/about-us.jpg"

const AboutUs = () => {
  return (
    <section>
      <Alternate centered backgroundHeight={350}>
        <h1>Experience Counts</h1>
      </Alternate>
      <Primary>
        <Row>
          <Col xs={12} md={8}>
            <p>
              As in any business, you should know who you are hiring. We are
              proud to let you know that SnowKings is individually owned by Mike
              Basaric. Mike has over thirty years in the Ice and hospitality
              businesses. Most recently he owned and operated Atlanta Ice
              Company the leading provider of institutional ice to restaurants,
              bars, special events and sports stadiums in the Atlanta Metro.
            </p>
            <p>
              He treats each customer as he would like to be treated. So no
              matter if it’s a private party or a major film production, Mike
              knows the value of your experience in terms of his future
              business. Please contact Mike directly by email or phone.
            </p>
          </Col>
          <Col xs={12} md={4}>
            <h4>Mike Basaric</h4>
            <p>snow@myiceman.com</p>
            <p>(770) 599-2862</p>
            <img src={AboutUsImg} alt="Trailer" />
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default AboutUs
