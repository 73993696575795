import React from "react"
import AboutUs from "../components/AboutUs"
import { Layout } from "../components/Layout"

const AboutUsPage = () => {
  return (
    <Layout>
      <AboutUs />
    </Layout>
  )
}

export default AboutUsPage
