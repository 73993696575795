import styled from "styled-components"
import { remCal } from "../../utils/sass.utils"

const HrefButton = styled.a`
  display: inline-block;
  text-decoration: none;
  padding: 1rem 1.25rem;
  background: transparent;
  border-radius: ${remCal(3)};
  color: white;
  box-shadow: inset 0px 0px 0px 2px white;
  margin-bottom: 1rem;
  &:hover {
    background: white;
    color: black;
    text-decoration: none;
  }
`
export default HrefButton
